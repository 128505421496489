import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Christmas can be very expensive. Plot a line of how much americans think
they're spending on Christmas gifts over the years.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/statistic_id246963_average-spending-on-christmas-gifts-in-the-us-1999-2018.xlsx"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/YGv1LNgKbn4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/6yqx23v6mn?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`Today we built a little line chart with two axes and emoji datapoints. Hover an
emoji, get a line highlighting where it falls on the axis. Makes it easy to see
what happened when.`}</p>
    <p>{`It's interesting to see how Christmas spending was on the rise and reached a
peak in 2007. Crashed in 2008 then started rising again. Great insight into the
US economy.`}</p>
    <p>{`When times are good, people buy gifts. When times are bad, people don't. 🧐`}</p>
    <p>{`To build this linechart we used the same insight
`}<a parentName="p" {...{
        "href": "/christmas-trees/"
      }}>{`as yesterday`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Our data is static and never changes. We don't expect to change positions and
size of our chart. That means we can cut some corners.`}</p>
    </blockquote>
    <p>{`Once again we load data in `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` using `}<inlineCode parentName="p">{`d3.tsv`}</inlineCode>{` to parse a tab
separated values file. We feed the result array of objects into a `}<inlineCode parentName="p">{`<Linechart>`}</inlineCode>{`
component.`}</p>
    <h2 {...{
      "id": "the-basic-linechart"
    }}>{`The basic linechart`}</h2>
    <p>{`Rendering a basic linechart was pretty quick: D3's got a line generator 🤙`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/money-spent/justline.png",
        "alt": "Just the line"
      }}></img></p>
    <p>{`That's an SVG `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` using `}<inlineCode parentName="p">{`d3.line`}</inlineCode>{` to create the `}<inlineCode parentName="p">{`d`}</inlineCode>{` shape attribute.
Wrapped into a React component it looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class Linechart extends React.Component {
  x = d3
    .scalePoint()
    .domain(this.props.data.map((d) => d.year))
    .range([0, this.props.width])
  y = d3
    .scaleLinear()
    .domain([500, d3.max(this.props.data, (d) => d.avg_spend)])
    .range([this.props.height, 0])

  line = d3
    .line()
    .x((d) => this.x(d.year))
    .y((d) => this.y(d.avg_spend))

  render() {
    const { x, y, data } = this.props

    return (
      <g transform={\`translate(\${x}, \${y})\`}>
        <Line d={this.line(data)} />
      </g>
    )
  }
}
`}</code></pre>
    <p>{`We define two scales, `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` to help us translate between datapoints and
coordinates on the screen. Without those year 2018 would render 2018 pixels to
the right and that's too much.`}</p>
    <p><inlineCode parentName="p">{`x`}</inlineCode>{` is a point scale, which like `}<a parentName="p" {...{
        "href": "/christmas-trees/"
      }}>{`yesterday's band scale`}</a>{`
neatly arranges datapoints along a axis. Unlike a band scale it places them in
points at the middle of each ragne.`}</p>
    <p><inlineCode parentName="p">{`y`}</inlineCode>{` is a boring old liear scale. Americans spend so much on gifts that we cut
off the domain at \\$500. Makes the graph more readable and less tall.`}</p>
    <p>{`Then we have the line generator. We define it with `}<inlineCode parentName="p">{`d3.line`}</inlineCode>{`, tell it how to
get `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` coordinates with our scales and leave the rest as defaults.`}</p>
    <p>{`Rendering is a matter of creating a wrapping `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` element to position our
graph and group all future additions. Inside, we render a styled `}<inlineCode parentName="p">{`<Line>`}</inlineCode>{`
component and feed data into the line generator. That handles the rest.`}</p>
    <p>{`You have to style lines or they come out invisible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Line = styled.path\`
  stroke-width: 3px;
  stroke: \${d3.color("green").brighter(1.5)};
  fill: none;
  stroke-linejoin: round;
\`
`}</code></pre>
    <p>{`Give it a nice thickness, some light green color, remove the default black
fill, and make edges round. Lovely.`}</p>
    <p>{`Note the `}<inlineCode parentName="p">{`d3.color('green').brighter(1.5)`}</inlineCode>{` trick. We can use D3 to manipulate
colors 🎨`}</p>
    <h2 {...{
      "id": "the-axes"
    }}>{`The axes`}</h2>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/money-spent/withaxes.png",
        "alt": "Line with axes"
      }}></img></p>
    <p>{`Because axes are a tricky best to build, we used a trick from
`}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for Data Visualization`}</a>{` -
blackbox rendering.`}</p>
    <p>{`That's when you take pure D3 code, wrap it in a React component, and let D3
handle the rendering. It's less efficient and doesn't scale as well, but
perfect for little things like this.`}</p>
    <p>{`You can use my `}<a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}>{`d3blackbox`}</a>{` library or make your own. I
used the lib 😛`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const BottomAxis = d3blackbox((anchor, props) => {
  const axis = d3.axisBottom().scale(props.scale)
  d3.select(anchor.current).call(axis)
})

const LeftAxis = d3blackbox((anchor, props) => {
  const axis = d3.axisLeft().scale(props.scale)
  d3.select(anchor.current).call(axis)
})
`}</code></pre>
    <p><inlineCode parentName="p">{`BottomAxis`}</inlineCode>{` and `}<inlineCode parentName="p">{`LeftAxis`}</inlineCode>{` are both tiny. Two lines of code is all you need to
render a axis with D3.`}</p>
    <ol>
      <li parentName="ol">{`Define the axis generator and give it a scale. We took it from props.`}</li>
      <li parentName="ol">{`Select the element you want to render into and call your generator`}</li>
    </ol>
    <p><inlineCode parentName="p">{`d3blackbox`}</inlineCode>{` handles the rest.`}</p>
    <p>{`It's a higher order component (hook version called `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` is also in the
package). Takes your render function whatever it is, renders an anchor element,
positions it with `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` props, and makes sure to call your render
function on any update.`}</p>
    <p>{`Quickest way to slap some D3 into some React 👌`}</p>
    <h2 {...{
      "id": "the--money-emojis"
    }}>{`The 💸 money emojis`}</h2>
    <p>{`How do you make a linechart more fun? You add money-flying-away emojis.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/money-spent/moneymoji.png",
        "alt": "Line with emojis"
      }}></img></p>
    <p>{`Interactive points on each edge of a linechart are pretty common after all.
Makes it easier to spot where the line breaks and shows actual data and where
it's just a line.`}</p>
    <p>{`Adding emojis happens in a loop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  data.map((d) => (
    <Money x={this.x(d.year)} y={this.y(d.avg_spend)}>
      💸
      <title>\${d.avg_spend}</title>
    </Money>
  ))
}
`}</code></pre>
    <p>{`Iterate through our data and render a styled `}<inlineCode parentName="p">{`text`}</inlineCode>{` component called `}<inlineCode parentName="p">{`Money`}</inlineCode>{`
for each datapoint. Using the same scales as we did for the linechart gives us
correct positioning out of the box.`}</p>
    <p>{`One of the many benefits of scales 😉`}</p>
    <p>{`Styling deals with setting emoji font size and centering text on the `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{`
anchor point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Money = styled.text\`
  font-size: 20px;
  cursor: pointer;
  text-anchor: middle;
  alignment-baseline: central;
\`
`}</code></pre>
    <p>{`Oh and adding a `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` tag to our text creates a default browser tooltip.
Hover over an emoji for a few seconds and it shows some extra info.`}</p>
    <h2 {...{
      "id": "a-highlight-for-easy-reading"
    }}>{`A highlight for easy reading`}</h2>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/money-spent/highlight.png",
        "alt": "A highlight to make life easier"
      }}></img></p>
    <p>{`Linecharts can be hard to read. With datapoints so far from the axes it can be
hard to see how everything lines up.`}</p>
    <p>{`So we added a line to help our users out.`}</p>
    <p>{`We keep track of what's currently highlighted in component state. When a value
exists, we use it to render a vertical line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class Linechart extends React.Component {
  state = {
    highlightYear: null
  };

  // ...

  highlight = year => this.setState({ highlightYear: year });
  unhighlight = () => this.setState({ highlightYear: null });

  // ...

  {highlightYear ? (
    <Highlight
      x1={this.x(highlightYear)}
      y1={-20}
      x2={this.x(highlightYear)}
      y2={height + 20}
    />
  ) : null}

  // ...

  <Money
    x={this.x(d.year)}
    y={this.y(d.avg_spend)}
    onMouseOver={() => this.highlight(d.year)}
    onMouseOut={this.unhighlight}
  >
`}</code></pre>
    <p>{`Nothing too crazy.`}</p>
    <p>{`We have a `}<inlineCode parentName="p">{`highlightYear`}</inlineCode>{` state. This gets set on `}<inlineCode parentName="p">{`onMouseOver`}</inlineCode>{` in the
`}<inlineCode parentName="p">{`<Money>`}</inlineCode>{` emoji. On `}<inlineCode parentName="p">{`onMouseOut`}</inlineCode>{`, we reset the highlight year back to `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <p>{`In the render method we then check whether `}<inlineCode parentName="p">{`highlightYear`}</inlineCode>{` is set. If it is, we
render a vertical line that's styled to be thin and lightgrey. If it isn't, we
don't.`}</p>
    <p>{`There's a lot we could do with that highlight to make it look smoother, but
time was up and this is good enough.`}</p>
    <h2 {...{
      "id": "what-you-learned-🧐"
    }}>{`What you learned 🧐`}</h2>
    <p>{`Today you learned:`}</p>
    <ul>
      <li parentName="ul">{`D3 point scales`}</li>
      <li parentName="ul">{`using class field values for D3 objects`}</li>
      <li parentName="ul">{`d3blackbox for simple D3 integrations`}</li>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`<title>`}</inlineCode>{` trick on text tags`}</li>
      <li parentName="ul">{`using D3 axes`}</li>
      <li parentName="ul">{`adding a little interactivity`}</li>
    </ul>
    <p>{`Enjoy ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      